import * as Operations from 'generated/operations';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;


export function useCommunityQuery(options: Omit<Urql.UseQueryArgs<Operations.CommunityQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.CommunityQuery, Operations.CommunityQueryVariables>({ query: Operations.CommunityDocument, ...options });
};

export function useAcceptApplicationMutation() {
  return Urql.useMutation<Operations.AcceptApplicationMutation, Operations.AcceptApplicationMutationVariables>(Operations.AcceptApplicationDocument);
};

export function useBlogConnectMediumMutation() {
  return Urql.useMutation<Operations.BlogConnectMediumMutation, Operations.BlogConnectMediumMutationVariables>(Operations.BlogConnectMediumDocument);
};

export function useCommunitySettingsMutation() {
  return Urql.useMutation<Operations.CommunitySettingsMutation, Operations.CommunitySettingsMutationVariables>(Operations.CommunitySettingsDocument);
};

export function useCommunitySettingsLogoMutation() {
  return Urql.useMutation<Operations.CommunitySettingsLogoMutation, Operations.CommunitySettingsLogoMutationVariables>(Operations.CommunitySettingsLogoDocument);
};

export function useConfirmEmailMutation() {
  return Urql.useMutation<Operations.ConfirmEmailMutation, Operations.ConfirmEmailMutationVariables>(Operations.ConfirmEmailDocument);
};

export function useConnectConfirmMutation() {
  return Urql.useMutation<Operations.ConnectConfirmMutation, Operations.ConnectConfirmMutationVariables>(Operations.ConnectConfirmDocument);
};

export function useConnectTelegramMutation() {
  return Urql.useMutation<Operations.ConnectTelegramMutation, Operations.ConnectTelegramMutationVariables>(Operations.ConnectTelegramDocument);
};

export function useConnectWhatsappConfirmMutation() {
  return Urql.useMutation<Operations.ConnectWhatsappConfirmMutation, Operations.ConnectWhatsappConfirmMutationVariables>(Operations.ConnectWhatsappConfirmDocument);
};

export function useConnectWhatsappMutation() {
  return Urql.useMutation<Operations.ConnectWhatsappMutation, Operations.ConnectWhatsappMutationVariables>(Operations.ConnectWhatsappDocument);
};

export function useCreateCommunityMutation() {
  return Urql.useMutation<Operations.CreateCommunityMutation, Operations.CreateCommunityMutationVariables>(Operations.CreateCommunityDocument);
};

export function useCreateNewCompanyMutation() {
  return Urql.useMutation<Operations.CreateNewCompanyMutation, Operations.CreateNewCompanyMutationVariables>(Operations.CreateNewCompanyDocument);
};

export function useCreateNewFeaturedMutation() {
  return Urql.useMutation<Operations.CreateNewFeaturedMutation, Operations.CreateNewFeaturedMutationVariables>(Operations.CreateNewFeaturedDocument);
};

export function useCreateNewHobbyMutation() {
  return Urql.useMutation<Operations.CreateNewHobbyMutation, Operations.CreateNewHobbyMutationVariables>(Operations.CreateNewHobbyDocument);
};

export function useCreateQualificationMutation() {
  return Urql.useMutation<Operations.CreateQualificationMutation, Operations.CreateQualificationMutationVariables>(Operations.CreateQualificationDocument);
};

export function useCreateNewSkillMutation() {
  return Urql.useMutation<Operations.CreateNewSkillMutation, Operations.CreateNewSkillMutationVariables>(Operations.CreateNewSkillDocument);
};

export function useCreateUniversityMutation() {
  return Urql.useMutation<Operations.CreateUniversityMutation, Operations.CreateUniversityMutationVariables>(Operations.CreateUniversityDocument);
};

export function useDeleteUserEducationMutation() {
  return Urql.useMutation<Operations.DeleteUserEducationMutation, Operations.DeleteUserEducationMutationVariables>(Operations.DeleteUserEducationDocument);
};

export function useDeleteUserExperienceMutation() {
  return Urql.useMutation<Operations.DeleteUserExperienceMutation, Operations.DeleteUserExperienceMutationVariables>(Operations.DeleteUserExperienceDocument);
};

export function useDeleteUserFeaturedMutation() {
  return Urql.useMutation<Operations.DeleteUserFeaturedMutation, Operations.DeleteUserFeaturedMutationVariables>(Operations.DeleteUserFeaturedDocument);
};

export function useDeleteUserProfileMutation() {
  return Urql.useMutation<Operations.DeleteUserProfileMutation, Operations.DeleteUserProfileMutationVariables>(Operations.DeleteUserProfileDocument);
};

export function useEditNewFeaturedMutation() {
  return Urql.useMutation<Operations.EditNewFeaturedMutation, Operations.EditNewFeaturedMutationVariables>(Operations.EditNewFeaturedDocument);
};

export function useSendHelpMessageResponseMutation() {
  return Urql.useMutation<Operations.SendHelpMessageResponseMutation, Operations.SendHelpMessageResponseMutationVariables>(Operations.SendHelpMessageResponseDocument);
};

export function useSendIntroMessageMutation() {
  return Urql.useMutation<Operations.SendIntroMessageMutation, Operations.SendIntroMessageMutationVariables>(Operations.SendIntroMessageDocument);
};

export function useLandingAiMutation() {
  return Urql.useMutation<Operations.LandingAiMutation, Operations.LandingAiMutationVariables>(Operations.LandingAiDocument);
};

export function useLandingAiMembersCancelMutation() {
  return Urql.useMutation<Operations.LandingAiMembersCancelMutation, Operations.LandingAiMembersCancelMutationVariables>(Operations.LandingAiMembersCancelDocument);
};

export function useLandingAiMembersRegenerateMutation() {
  return Urql.useMutation<Operations.LandingAiMembersRegenerateMutation, Operations.LandingAiMembersRegenerateMutationVariables>(Operations.LandingAiMembersRegenerateDocument);
};

export function useLandingApplicationFieldMutation() {
  return Urql.useMutation<Operations.LandingApplicationFieldMutation, Operations.LandingApplicationFieldMutationVariables>(Operations.LandingApplicationFieldDocument);
};

export function useSaveLandingApplicationFieldsMutation() {
  return Urql.useMutation<Operations.SaveLandingApplicationFieldsMutation, Operations.SaveLandingApplicationFieldsMutationVariables>(Operations.SaveLandingApplicationFieldsDocument);
};

export function useLandingApplyMutation() {
  return Urql.useMutation<Operations.LandingApplyMutation, Operations.LandingApplyMutationVariables>(Operations.LandingApplyDocument);
};

export function useLandingBuilderVideoSeenMutation() {
  return Urql.useMutation<Operations.LandingBuilderVideoSeenMutation, Operations.LandingBuilderVideoSeenMutationVariables>(Operations.LandingBuilderVideoSeenDocument);
};

export function useLandingJoinSettingsMutation() {
  return Urql.useMutation<Operations.LandingJoinSettingsMutation, Operations.LandingJoinSettingsMutationVariables>(Operations.LandingJoinSettingsDocument);
};

export function useLandingSecondaryButtonMutation() {
  return Urql.useMutation<Operations.LandingSecondaryButtonMutation, Operations.LandingSecondaryButtonMutationVariables>(Operations.LandingSecondaryButtonDocument);
};

export function useLoadCitiesMutation() {
  return Urql.useMutation<Operations.LoadCitiesMutation, Operations.LoadCitiesMutationVariables>(Operations.LoadCitiesDocument);
};

export function useLoadCompaniesMutation() {
  return Urql.useMutation<Operations.LoadCompaniesMutation, Operations.LoadCompaniesMutationVariables>(Operations.LoadCompaniesDocument);
};

export function useLoadHobbiesMutation() {
  return Urql.useMutation<Operations.LoadHobbiesMutation, Operations.LoadHobbiesMutationVariables>(Operations.LoadHobbiesDocument);
};

export function useLoadQualificationsMutation() {
  return Urql.useMutation<Operations.LoadQualificationsMutation, Operations.LoadQualificationsMutationVariables>(Operations.LoadQualificationsDocument);
};

export function useLoadSkillsMutation() {
  return Urql.useMutation<Operations.LoadSkillsMutation, Operations.LoadSkillsMutationVariables>(Operations.LoadSkillsDocument);
};

export function useLoadUniversitiesMutation() {
  return Urql.useMutation<Operations.LoadUniversitiesMutation, Operations.LoadUniversitiesMutationVariables>(Operations.LoadUniversitiesDocument);
};

export function useLoginUserMutation() {
  return Urql.useMutation<Operations.LoginUserMutation, Operations.LoginUserMutationVariables>(Operations.LoginUserDocument);
};

export function useLogoutUserMutation() {
  return Urql.useMutation<Operations.LogoutUserMutation, Operations.LogoutUserMutationVariables>(Operations.LogoutUserDocument);
};

export function useMemberConnectTelegramMutation() {
  return Urql.useMutation<Operations.MemberConnectTelegramMutation, Operations.MemberConnectTelegramMutationVariables>(Operations.MemberConnectTelegramDocument);
};

export function useMemberConnectWhatsappMutation() {
  return Urql.useMutation<Operations.MemberConnectWhatsappMutation, Operations.MemberConnectWhatsappMutationVariables>(Operations.MemberConnectWhatsappDocument);
};

export function useMessageMutation() {
  return Urql.useMutation<Operations.MessageMutation, Operations.MessageMutationVariables>(Operations.MessageDocument);
};

export function useRegisterUserMutation() {
  return Urql.useMutation<Operations.RegisterUserMutation, Operations.RegisterUserMutationVariables>(Operations.RegisterUserDocument);
};

export function useRejectApplicationMutation() {
  return Urql.useMutation<Operations.RejectApplicationMutation, Operations.RejectApplicationMutationVariables>(Operations.RejectApplicationDocument);
};

export function useResetPasswordMutation() {
  return Urql.useMutation<Operations.ResetPasswordMutation, Operations.ResetPasswordMutationVariables>(Operations.ResetPasswordDocument);
};

export function useResetPasswordConfirmMutation() {
  return Urql.useMutation<Operations.ResetPasswordConfirmMutation, Operations.ResetPasswordConfirmMutationVariables>(Operations.ResetPasswordConfirmDocument);
};

export function useSaveCanHelpMutation() {
  return Urql.useMutation<Operations.SaveCanHelpMutation, Operations.SaveCanHelpMutationVariables>(Operations.SaveCanHelpDocument);
};

export function useSaveHobbiesMutation() {
  return Urql.useMutation<Operations.SaveHobbiesMutation, Operations.SaveHobbiesMutationVariables>(Operations.SaveHobbiesDocument);
};

export function useNeedHelpClientMutation() {
  return Urql.useMutation<Operations.NeedHelpClientMutation, Operations.NeedHelpClientMutationVariables>(Operations.NeedHelpClientDocument);
};

export function useSaveOnBoardingFinishedMutation() {
  return Urql.useMutation<Operations.SaveOnBoardingFinishedMutation, Operations.SaveOnBoardingFinishedMutationVariables>(Operations.SaveOnBoardingFinishedDocument);
};

export function useSaveSkillsMutation() {
  return Urql.useMutation<Operations.SaveSkillsMutation, Operations.SaveSkillsMutationVariables>(Operations.SaveSkillsDocument);
};

export function useSetCommunityDescriptionMutation() {
  return Urql.useMutation<Operations.SetCommunityDescriptionMutation, Operations.SetCommunityDescriptionMutationVariables>(Operations.SetCommunityDescriptionDocument);
};

export function useSetCommunityGoalsMutation() {
  return Urql.useMutation<Operations.SetCommunityGoalsMutation, Operations.SetCommunityGoalsMutationVariables>(Operations.SetCommunityGoalsDocument);
};

export function useSignupMutation() {
  return Urql.useMutation<Operations.SignupMutation, Operations.SignupMutationVariables>(Operations.SignupDocument);
};

export function useStartMutation() {
  return Urql.useMutation<Operations.StartMutation, Operations.StartMutationVariables>(Operations.StartDocument);
};

export function useTelegramAuthUserMutation() {
  return Urql.useMutation<Operations.TelegramAuthUserMutation, Operations.TelegramAuthUserMutationVariables>(Operations.TelegramAuthUserDocument);
};

export function useUpdateEducationMutation() {
  return Urql.useMutation<Operations.UpdateEducationMutation, Operations.UpdateEducationMutationVariables>(Operations.UpdateEducationDocument);
};

export function useUpdateExperienceMutation() {
  return Urql.useMutation<Operations.UpdateExperienceMutation, Operations.UpdateExperienceMutationVariables>(Operations.UpdateExperienceDocument);
};

export function useUpdateBaseProfileMutation() {
  return Urql.useMutation<Operations.UpdateBaseProfileMutation, Operations.UpdateBaseProfileMutationVariables>(Operations.UpdateBaseProfileDocument);
};

export function useWorkedWithMutation() {
  return Urql.useMutation<Operations.WorkedWithMutation, Operations.WorkedWithMutationVariables>(Operations.WorkedWithDocument);
};

export function useWorkedWithConfirmMutation() {
  return Urql.useMutation<Operations.WorkedWithConfirmMutation, Operations.WorkedWithConfirmMutationVariables>(Operations.WorkedWithConfirmDocument);
};

export function useLandingPublishMutation() {
  return Urql.useMutation<Operations.LandingPublishMutation, Operations.LandingPublishMutationVariables>(Operations.LandingPublishDocument);
};

export function useLandingSetBenefitsFieldsMutation() {
  return Urql.useMutation<Operations.LandingSetBenefitsFieldsMutation, Operations.LandingSetBenefitsFieldsMutationVariables>(Operations.LandingSetBenefitsFieldsDocument);
};

export function useLandingSetCtaFieldsMutation() {
  return Urql.useMutation<Operations.LandingSetCtaFieldsMutation, Operations.LandingSetCtaFieldsMutationVariables>(Operations.LandingSetCtaFieldsDocument);
};

export function useLandingSetHeaderFieldsMutation() {
  return Urql.useMutation<Operations.LandingSetHeaderFieldsMutation, Operations.LandingSetHeaderFieldsMutationVariables>(Operations.LandingSetHeaderFieldsDocument);
};

export function useLandingSetHeaderImageMutation() {
  return Urql.useMutation<Operations.LandingSetHeaderImageMutation, Operations.LandingSetHeaderImageMutationVariables>(Operations.LandingSetHeaderImageDocument);
};

export function useLandingSetMemberFieldsMutation() {
  return Urql.useMutation<Operations.LandingSetMemberFieldsMutation, Operations.LandingSetMemberFieldsMutationVariables>(Operations.LandingSetMemberFieldsDocument);
};

export function useLandingSetMemberImageMutation() {
  return Urql.useMutation<Operations.LandingSetMemberImageMutation, Operations.LandingSetMemberImageMutationVariables>(Operations.LandingSetMemberImageDocument);
};

export function useLandingSetRequirementsFieldsMutation() {
  return Urql.useMutation<Operations.LandingSetRequirementsFieldsMutation, Operations.LandingSetRequirementsFieldsMutationVariables>(Operations.LandingSetRequirementsFieldsDocument);
};

export function useSaveImageProfileMutation() {
  return Urql.useMutation<Operations.SaveImageProfileMutation, Operations.SaveImageProfileMutationVariables>(Operations.SaveImageProfileDocument);
};

export function useSaveLinkedInMutation() {
  return Urql.useMutation<Operations.SaveLinkedInMutation, Operations.SaveLinkedInMutationVariables>(Operations.SaveLinkedInDocument);
};

export function useSaveSocialMutation() {
  return Urql.useMutation<Operations.SaveSocialMutation, Operations.SaveSocialMutationVariables>(Operations.SaveSocialDocument);
};

export function useSaveLocationMutation() {
  return Urql.useMutation<Operations.SaveLocationMutation, Operations.SaveLocationMutationVariables>(Operations.SaveLocationDocument);
};

export function useSetPricingMutation() {
  return Urql.useMutation<Operations.SetPricingMutation, Operations.SetPricingMutationVariables>(Operations.SetPricingDocument);
};

export function useSetSubscriptionTypeMutation() {
  return Urql.useMutation<Operations.SetSubscriptionTypeMutation, Operations.SetSubscriptionTypeMutationVariables>(Operations.SetSubscriptionTypeDocument);
};

export function useActivityQuery(options?: Omit<Urql.UseQueryArgs<Operations.ActivityQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.ActivityQuery, Operations.ActivityQueryVariables>({ query: Operations.ActivityDocument, ...options });
};

export function useActivityCountQuery(options?: Omit<Urql.UseQueryArgs<Operations.ActivityCountQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.ActivityCountQuery, Operations.ActivityCountQueryVariables>({ query: Operations.ActivityCountDocument, ...options });
};

export function useAllCitiesQuery(options: Omit<Urql.UseQueryArgs<Operations.AllCitiesQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.AllCitiesQuery, Operations.AllCitiesQueryVariables>({ query: Operations.AllCitiesDocument, ...options });
};

export function useAnalyticsActivationQuery(options?: Omit<Urql.UseQueryArgs<Operations.AnalyticsActivationQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.AnalyticsActivationQuery, Operations.AnalyticsActivationQueryVariables>({ query: Operations.AnalyticsActivationDocument, ...options });
};

export function useAnalyticsApplicationsQuery(options: Omit<Urql.UseQueryArgs<Operations.AnalyticsApplicationsQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.AnalyticsApplicationsQuery, Operations.AnalyticsApplicationsQueryVariables>({ query: Operations.AnalyticsApplicationsDocument, ...options });
};

export function useAnalyticsHeatmapMessagesQuery(options?: Omit<Urql.UseQueryArgs<Operations.AnalyticsHeatmapMessagesQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.AnalyticsHeatmapMessagesQuery, Operations.AnalyticsHeatmapMessagesQueryVariables>({ query: Operations.AnalyticsHeatmapMessagesDocument, ...options });
};

export function useAnalyticsMembersCountriesQuery(options?: Omit<Urql.UseQueryArgs<Operations.AnalyticsMembersCountriesQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.AnalyticsMembersCountriesQuery, Operations.AnalyticsMembersCountriesQueryVariables>({ query: Operations.AnalyticsMembersCountriesDocument, ...options });
};

export function useAnalyticsMembersLeftListQuery(options: Omit<Urql.UseQueryArgs<Operations.AnalyticsMembersLeftListQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.AnalyticsMembersLeftListQuery, Operations.AnalyticsMembersLeftListQueryVariables>({ query: Operations.AnalyticsMembersLeftListDocument, ...options });
};

export function useAnalyticsMembersLeftV2Query(options?: Omit<Urql.UseQueryArgs<Operations.AnalyticsMembersLeftV2QueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.AnalyticsMembersLeftV2Query, Operations.AnalyticsMembersLeftV2QueryVariables>({ query: Operations.AnalyticsMembersLeftV2Document, ...options });
};

export function useAnalyticsMembersLifetimeListQuery(options?: Omit<Urql.UseQueryArgs<Operations.AnalyticsMembersLifetimeListQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.AnalyticsMembersLifetimeListQuery, Operations.AnalyticsMembersLifetimeListQueryVariables>({ query: Operations.AnalyticsMembersLifetimeListDocument, ...options });
};

export function useAnalyticsMembersTopListQuery(options: Omit<Urql.UseQueryArgs<Operations.AnalyticsMembersTopListQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.AnalyticsMembersTopListQuery, Operations.AnalyticsMembersTopListQueryVariables>({ query: Operations.AnalyticsMembersTopListDocument, ...options });
};

export function useAnalyticsMessageTypesListQuery(options: Omit<Urql.UseQueryArgs<Operations.AnalyticsMessageTypesListQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.AnalyticsMessageTypesListQuery, Operations.AnalyticsMessageTypesListQueryVariables>({ query: Operations.AnalyticsMessageTypesListDocument, ...options });
};

export function useAnalyticsStagesQuery(options?: Omit<Urql.UseQueryArgs<Operations.AnalyticsStagesQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.AnalyticsStagesQuery, Operations.AnalyticsStagesQueryVariables>({ query: Operations.AnalyticsStagesDocument, ...options });
};

export function useAnalyticsApplicationsSummaryQuery(options: Omit<Urql.UseQueryArgs<Operations.AnalyticsApplicationsSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.AnalyticsApplicationsSummaryQuery, Operations.AnalyticsApplicationsSummaryQueryVariables>({ query: Operations.AnalyticsApplicationsSummaryDocument, ...options });
};

export function useAnalyticsLikesSummaryQuery(options: Omit<Urql.UseQueryArgs<Operations.AnalyticsLikesSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.AnalyticsLikesSummaryQuery, Operations.AnalyticsLikesSummaryQueryVariables>({ query: Operations.AnalyticsLikesSummaryDocument, ...options });
};

export function useAnalyticsMembersSummaryQuery(options: Omit<Urql.UseQueryArgs<Operations.AnalyticsMembersSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.AnalyticsMembersSummaryQuery, Operations.AnalyticsMembersSummaryQueryVariables>({ query: Operations.AnalyticsMembersSummaryDocument, ...options });
};

export function useAnalyticsMessagesSummaryQuery(options: Omit<Urql.UseQueryArgs<Operations.AnalyticsMessagesSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.AnalyticsMessagesSummaryQuery, Operations.AnalyticsMessagesSummaryQueryVariables>({ query: Operations.AnalyticsMessagesSummaryDocument, ...options });
};

export function useAnalyticsV2MembersQuery(options: Omit<Urql.UseQueryArgs<Operations.AnalyticsV2MembersQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.AnalyticsV2MembersQuery, Operations.AnalyticsV2MembersQueryVariables>({ query: Operations.AnalyticsV2MembersDocument, ...options });
};

export function useAnalyticsV2MessagesQuery(options: Omit<Urql.UseQueryArgs<Operations.AnalyticsV2MessagesQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.AnalyticsV2MessagesQuery, Operations.AnalyticsV2MessagesQueryVariables>({ query: Operations.AnalyticsV2MessagesDocument, ...options });
};

export function useAnalyticsV2ReactionsQuery(options: Omit<Urql.UseQueryArgs<Operations.AnalyticsV2ReactionsQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.AnalyticsV2ReactionsQuery, Operations.AnalyticsV2ReactionsQueryVariables>({ query: Operations.AnalyticsV2ReactionsDocument, ...options });
};

export function useAnalyticsV2StatsQuery(options: Omit<Urql.UseQueryArgs<Operations.AnalyticsV2StatsQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.AnalyticsV2StatsQuery, Operations.AnalyticsV2StatsQueryVariables>({ query: Operations.AnalyticsV2StatsDocument, ...options });
};

export function useLandingApplicationFieldsQuery(options?: Omit<Urql.UseQueryArgs<Operations.LandingApplicationFieldsQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.LandingApplicationFieldsQuery, Operations.LandingApplicationFieldsQueryVariables>({ query: Operations.LandingApplicationFieldsDocument, ...options });
};

export function useBlogRecentQuery(options?: Omit<Urql.UseQueryArgs<Operations.BlogRecentQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.BlogRecentQuery, Operations.BlogRecentQueryVariables>({ query: Operations.BlogRecentDocument, ...options });
};

export function useCitiesQuery(options?: Omit<Urql.UseQueryArgs<Operations.CitiesQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.CitiesQuery, Operations.CitiesQueryVariables>({ query: Operations.CitiesDocument, ...options });
};

export function useCommunitiesQuery(options?: Omit<Urql.UseQueryArgs<Operations.CommunitiesQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.CommunitiesQuery, Operations.CommunitiesQueryVariables>({ query: Operations.CommunitiesDocument, ...options });
};

export function useApplicationQuery(options: Omit<Urql.UseQueryArgs<Operations.ApplicationQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.ApplicationQuery, Operations.ApplicationQueryVariables>({ query: Operations.ApplicationDocument, ...options });
};

export function useApplicationsQuery(options: Omit<Urql.UseQueryArgs<Operations.ApplicationsQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.ApplicationsQuery, Operations.ApplicationsQueryVariables>({ query: Operations.ApplicationsDocument, ...options });
};

export function useApplicationsCountQuery(options: Omit<Urql.UseQueryArgs<Operations.ApplicationsCountQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.ApplicationsCountQuery, Operations.ApplicationsCountQueryVariables>({ query: Operations.ApplicationsCountDocument, ...options });
};

export function useCommunityInfoQuery(options?: Omit<Urql.UseQueryArgs<Operations.CommunityInfoQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.CommunityInfoQuery, Operations.CommunityInfoQueryVariables>({ query: Operations.CommunityInfoDocument, ...options });
};

export function useCompanyNodesQuery(options?: Omit<Urql.UseQueryArgs<Operations.CompanyNodesQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.CompanyNodesQuery, Operations.CompanyNodesQueryVariables>({ query: Operations.CompanyNodesDocument, ...options });
};

export function useCurrentUserQuery(options?: Omit<Urql.UseQueryArgs<Operations.CurrentUserQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.CurrentUserQuery, Operations.CurrentUserQueryVariables>({ query: Operations.CurrentUserDocument, ...options });
};

export function useEducationQuery(options: Omit<Urql.UseQueryArgs<Operations.EducationQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.EducationQuery, Operations.EducationQueryVariables>({ query: Operations.EducationDocument, ...options });
};

export function useExperienceQuery(options: Omit<Urql.UseQueryArgs<Operations.ExperienceQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.ExperienceQuery, Operations.ExperienceQueryVariables>({ query: Operations.ExperienceDocument, ...options });
};

export function useFeaturedQuery(options: Omit<Urql.UseQueryArgs<Operations.FeaturedQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.FeaturedQuery, Operations.FeaturedQueryVariables>({ query: Operations.FeaturedDocument, ...options });
};

export function useFiltersValuesQuery(options?: Omit<Urql.UseQueryArgs<Operations.FiltersValuesQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.FiltersValuesQuery, Operations.FiltersValuesQueryVariables>({ query: Operations.FiltersValuesDocument, ...options });
};

export function useHobbiesQuery(options?: Omit<Urql.UseQueryArgs<Operations.HobbiesQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.HobbiesQuery, Operations.HobbiesQueryVariables>({ query: Operations.HobbiesDocument, ...options });
};

export function useInviteUserQuery(options?: Omit<Urql.UseQueryArgs<Operations.InviteUserQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.InviteUserQuery, Operations.InviteUserQueryVariables>({ query: Operations.InviteUserDocument, ...options });
};

export function useIsLoggedInQuery(options?: Omit<Urql.UseQueryArgs<Operations.IsLoggedInQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.IsLoggedInQuery, Operations.IsLoggedInQueryVariables>({ query: Operations.IsLoggedInDocument, ...options });
};

export function useJobTitleNodesQuery(options?: Omit<Urql.UseQueryArgs<Operations.JobTitleNodesQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.JobTitleNodesQuery, Operations.JobTitleNodesQueryVariables>({ query: Operations.JobTitleNodesDocument, ...options });
};

export function useJobTypesQuery(options?: Omit<Urql.UseQueryArgs<Operations.JobTypesQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.JobTypesQuery, Operations.JobTypesQueryVariables>({ query: Operations.JobTypesDocument, ...options });
};

export function useLandingQuery(options?: Omit<Urql.UseQueryArgs<Operations.LandingQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.LandingQuery, Operations.LandingQueryVariables>({ query: Operations.LandingDocument, ...options });
};

export function useLandingMembersQuery(options?: Omit<Urql.UseQueryArgs<Operations.LandingMembersQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.LandingMembersQuery, Operations.LandingMembersQueryVariables>({ query: Operations.LandingMembersDocument, ...options });
};

export function useLoginWhatsappMutation() {
  return Urql.useMutation<Operations.LoginWhatsappMutation, Operations.LoginWhatsappMutationVariables>(Operations.LoginWhatsappDocument);
};

export function useMembersQuery(options?: Omit<Urql.UseQueryArgs<Operations.MembersQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.MembersQuery, Operations.MembersQueryVariables>({ query: Operations.MembersDocument, ...options });
};

export function usePanelV2Query(options?: Omit<Urql.UseQueryArgs<Operations.PanelV2QueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.PanelV2Query, Operations.PanelV2QueryVariables>({ query: Operations.PanelV2Document, ...options });
};

export function useQualificationNodesQuery(options?: Omit<Urql.UseQueryArgs<Operations.QualificationNodesQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.QualificationNodesQuery, Operations.QualificationNodesQueryVariables>({ query: Operations.QualificationNodesDocument, ...options });
};

export function useRequestsQuery(options?: Omit<Urql.UseQueryArgs<Operations.RequestsQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.RequestsQuery, Operations.RequestsQueryVariables>({ query: Operations.RequestsDocument, ...options });
};

export function useSkillsQuery(options?: Omit<Urql.UseQueryArgs<Operations.SkillsQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.SkillsQuery, Operations.SkillsQueryVariables>({ query: Operations.SkillsDocument, ...options });
};

export function useStatMembersListQuery(options?: Omit<Urql.UseQueryArgs<Operations.StatMembersListQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.StatMembersListQuery, Operations.StatMembersListQueryVariables>({ query: Operations.StatMembersListDocument, ...options });
};

export function useStatMessagesListQuery(options?: Omit<Urql.UseQueryArgs<Operations.StatMessagesListQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.StatMessagesListQuery, Operations.StatMessagesListQueryVariables>({ query: Operations.StatMessagesListDocument, ...options });
};

export function useStripeCustomerPortalUrlQuery(options: Omit<Urql.UseQueryArgs<Operations.StripeCustomerPortalUrlQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.StripeCustomerPortalUrlQuery, Operations.StripeCustomerPortalUrlQueryVariables>({ query: Operations.StripeCustomerPortalUrlDocument, ...options });
};

export function useStripeEmbeddedCodeQuery(options?: Omit<Urql.UseQueryArgs<Operations.StripeEmbeddedCodeQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.StripeEmbeddedCodeQuery, Operations.StripeEmbeddedCodeQueryVariables>({ query: Operations.StripeEmbeddedCodeDocument, ...options });
};

export function useSubscriptionQuery(options?: Omit<Urql.UseQueryArgs<Operations.SubscriptionQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.SubscriptionQuery, Operations.SubscriptionQueryVariables>({ query: Operations.SubscriptionDocument, ...options });
};

export function useTelegramConfigurationQuery(options?: Omit<Urql.UseQueryArgs<Operations.TelegramConfigurationQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.TelegramConfigurationQuery, Operations.TelegramConfigurationQueryVariables>({ query: Operations.TelegramConfigurationDocument, ...options });
};

export function useUniversityNodesQuery(options?: Omit<Urql.UseQueryArgs<Operations.UniversityNodesQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.UniversityNodesQuery, Operations.UniversityNodesQueryVariables>({ query: Operations.UniversityNodesDocument, ...options });
};

export function useUserQuery(options?: Omit<Urql.UseQueryArgs<Operations.UserQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.UserQuery, Operations.UserQueryVariables>({ query: Operations.UserDocument, ...options });
};

export function useUsersQuery(options?: Omit<Urql.UseQueryArgs<Operations.UsersQueryVariables>, 'query'>) {
  return Urql.useQuery<Operations.UsersQuery, Operations.UsersQueryVariables>({ query: Operations.UsersDocument, ...options });
};